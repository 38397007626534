.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  background: white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  color: #1a1a1a;
}

h1 {
  font-size: 3.5rem;
  margin-bottom: 2rem;
  background: linear-gradient(45deg, #61dafb, #a0ff88);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.card-visual {
  margin: 2rem 0;
}

.debit-card {
  width: 320px;
  height: 200px;
  background: linear-gradient(135deg, white 0%, #f8f8f8 100%);
  border-radius: 15px;
  padding: 20px;
  position: relative;
  box-shadow: 
    15px 15px 30px rgba(0, 0, 0, 0.2),
    -5px -5px 20px rgba(255, 255, 255, 0.8),
    inset 0px 0px 10px rgba(255, 255, 255, 0.2);
  transform: perspective(1000px) rotateX(2deg);
  border: .1px solid rgba(0, 0, 0, 0.3);
}

.card-chip {
  width: 50px;
  height: 40px;
  background: #ffd700;
  border-radius: 8px;
  position: absolute;
  left: 40px;  /* Changed from 20px to 80px */
  top: 45%;
  transform: translateY(-50%);
}

.card-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  letter-spacing: 4px;
  color: #1a1a1a;
}

.value-props {
  margin: 3rem 0;
}

.features {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

.feature {
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  padding: 1.5rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  color: #1a1a1a;
}

.feature h3 {
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}

.waitlist-button {
  background: linear-gradient(45deg, #61dafb, #a0ff88);
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 30px;
  color: white;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  font-weight: bold;
  margin-top: 2rem;
}

.waitlist-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(97, 218, 251, 0.3);
}

@media (max-width: 768px) {
  .features {
    flex-direction: column;
    align-items: center;
  }
  
  .feature {
    width: 100%;
    max-width: none;
  }
  
  h1 {
    font-size: 2.5rem;
  }
}

.footer-links {
  padding: 2rem;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  background: white;
}

.link-button {
  background: none;
  border: none;
  color: #1a1a1a;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 0.9rem;
  text-decoration: underline;
}

.link-button:hover {
  color: #61dafb;
}

.separator {
  margin: 0 0.5rem;
  color: #1a1a1a;
}

.back-button {
  position: absolute;
  top: 2rem;
  left: 2rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1rem;
}

.legal-content {
  max-width: 800px;
  text-align: left;
}

.legal-content h1 {
  font-size: 2rem;
  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: initial;
}

.legal-content p {
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

.whale-logo {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.7;
  color: #1a1a1a;
  width: 100px;
  height: 100px;
}

.whale-logo:hover {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
.card-tagline {
  font-size: 1.8rem;
  margin: 3rem 0 2rem 0;
  font-weight: 500;
  max-width: 600px;
  text-align: center;
  background: linear-gradient(45deg, #61dafb, #a0ff88);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
  .debit-card {
    width: 280px;
    height: 175px;
  }
}

@media (max-width: 480px) {
  .debit-card {
    width: 240px;
    height: 150px;
  }
  
  .card-chip {
    width: 40px;
    height: 32px;
    left: 30px;
  }
  
  .whale-logo {
    width: 80px;
    height: 80px;
    top: 15px;
    right: 15px;
  }
  .card-tagline {
    font-size: 1.55rem;
    margin: 2rem 0 1.5rem 0;
  }
}

.no-wrap {
  white-space: nowrap;
}

.mobile-only {
  display: none;
}

@media (max-width: 480px) {
  .mobile-only {
    display: inline;
  }
  
  .tagline-features br:not(.mobile-only) {
    display: none;
  }
}

